import React, { useCallback, useEffect, useState } from 'react';
import axios from 'axios';

import './index.scss';

const t = i18n.t;

export default function ThirdStep({
  creationData,
  onSubmit,
  onPrevious,
  onClose,
}) {
  const [submitting, setSubmitting] = useState(false);
  const [textAreaVisible, setTextAreaVisible] = useState(false);
  const [customMessage, setCustomMessage] = useState('');
  const invalidMessage = (customMessage !== '' && customMessage.trim() === '');

  const toggleVisibility = useCallback(() => {
    setTextAreaVisible((v) => !v)
  }, [setTextAreaVisible]);

  const handleFormSubmit = async (e) => {
    e.preventDefault();

    const customMessageIsEmpty = (customMessage === '');
    const finalMessage = customMessageIsEmpty
      ? t('default_meeting_suggestion_message')
      : customMessage;

    
    await onSubmit({
      spotType: 'custom',
      spotCustomText: finalMessage,
      message: finalMessage,
    });
  };

  return (
    <div className="alternative-meeting-step-container">
      <h3>{t('no_slots_available_title')}</h3>
      <p>{t('no_slots_available_description')}</p>
      <div className="alternative-meeting-step-action-section">
        <div className="alternative-meeting-step-actions">
          <button className="c-btn c-btn--secondary" onClick={onPrevious}>
            {t('back_to_datetime_picker')}
          </button>
          <button
            className={`c-btn c-btn--secondary ${textAreaVisible ? 'c-btn--negative' : ''}`}
            onClick={toggleVisibility}
          >
            {t('suggest_meeting_place')}
          </button>
          <button className="c-btn c-btn--secondary" onClick={onClose}>
            {t('cancel')}
          </button>
        </div>
      </div>
      {textAreaVisible && (
        <form onSubmit={handleFormSubmit} className="alternative-meeting-step-message-section">
          <textarea
            type="textarea"
            placeholder={t('default_meeting_suggestion_message')}
            value={customMessage}
            onChange={(e) => setCustomMessage(e.target.value)}
            rows="4"
            className="c-form__field"
          />
          <div className="alternative-meeting-step-button-container">
            <button
              type="submit"
              disabled={invalidMessage || submitting}
              className="c-btn u-float-right"
            >
              {submitting ? t('sending') : t('send')}
            </button>
          </div>
        </form>
      )}
    </div>
  );
}
